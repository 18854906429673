import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalConstants } from 'src/app/shared/services/modal/modal.constants';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { UserPublicModalComponent } from '../../user-public-modal/user-public-modal.component';
import { LacUser } from 'src/app/campaign-module/models/campaign.model';
import { LacService } from 'src/app/services/lac.service';

@Component({
  selector: 'app-team-leaderboard-modal',
  templateUrl: './team-leaderboard-modal.component.html',
  styleUrls: ['./team-leaderboard-modal.component.scss']
})
export class TeamLeaderboardModalComponent implements OnInit {

  @Input() teamId: number;
  @Input() teamName: string;
  @Input() fullLeaderboard: LacUser[];
  isLoading = true;
  screenWidth: number;
  screenHeight: number;
  leaderboard = [];

  constructor(private dialogRef: MatDialogRef<TeamLeaderboardModalComponent>,
              private modal: ModalService,
              private lacService: LacService,
              @Inject(MAT_DIALOG_DATA) data) {
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    for (const user of this.fullLeaderboard) {
      if (user.team_id == this.teamId) {
        this.leaderboard.push(user);
      }
    }
    this.isLoading = false;
  }

  closeModal() {
    this.dialogRef.close();
  }

  openUserPublicModal(accountId: number) {
    const modalMinHeight = this.screenWidth > 767 ? (this.screenHeight <= 754 ? this.screenHeight : 754) : this.screenHeight;
    const modalMinWidth = this.screenWidth > 767 ? 816 : this.screenWidth;
    const userPublicModalRef = this.modal.openAdaptableModal({}, UserPublicModalComponent,
      this.screenWidth > 767 ? ModalConstants.DEFAULT_PANEL_CLASS : ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS,
      modalMinWidth, modalMinHeight, modalMinWidth, modalMinHeight);

    userPublicModalRef.componentInstance.accountId = accountId;
    userPublicModalRef.afterClosed().subscribe(() => {
      this.closeModal();
    });
  }

  onImageError(user: LacUser): void {
    user.profile_picture = '/assets/icons/no-avatar.png';
  }
}
