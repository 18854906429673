import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

/**
 * For correct placement, parent HTML element needs the CSS property 'position' set to absolute.
 */
@Directive({
  selector: '[nsTooltip]'
})
export class NsTooltipDirective implements OnDestroy {

  @Input() tooltipTitle = '';
  @Input() tooltipText = '';
  @Input() tooltipWidth?: number;
  @Input() tooltipWidthMaxContent ? = false;
  @Input() tooltipClass = 'ns-tooltip-container-1';
  @Input() left = false;
  @Input() top = false;
  @Input() center ? = false;
  @Input() delay ? = 190; // Optional delay input, in ms
  @Input() dissapearDelay ? = 0;
  @Input() disableTooltip ? = false;
  @Input() padding ? = 20;
  @Input() marginTop ? = 0;
  @Input() rightPosition?: number;

  private tooltipReference: HTMLDivElement;
  private showingTooltip: boolean;
  private timer: any;
  private insideTimer: any;
  private closeTimer: any;

  constructor(private el: ElementRef) {
  }

  ngOnDestroy(): void {
    if (this.tooltipReference) {
      this.tooltipReference.remove();
    }
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (!this.showingTooltip && !this.disableTooltip) {
      this.timer = setTimeout(() => {
        this.createTooltip();
      }, this.delay);

      this.insideTimer = setTimeout(() => {
        this.tooltipReference.remove();
      }, 60000);
    }

    if (this.showingTooltip) {
      if (!this.insideTimer) {
        clearTimeout(this.closeTimer);
        setTimeout(() => {
          this.tooltipReference.remove();
        }, 60000);
      }
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.timer) {
     this.closeTimer = setTimeout(() => {
        clearTimeout(this.timer);
      }, this.dissapearDelay);
    }
    if (this.tooltipReference) {
      this.closeTimer = setTimeout(() => {
        this.showingTooltip = false;
        this.tooltipReference.remove();
      }, this.dissapearDelay);
    }
  }

  private createTooltip(): void {
    this.showingTooltip = true;

    const tooltip = document.createElement('div');
    if (this.tooltipTitle) {
      tooltip.innerHTML = '<div class="tooltip-title">' + this.tooltipTitle + '</div>' +
      '<div class="tooltip-text">' + this.tooltipText + '</div>';
    } else {
      tooltip.innerHTML = '<div class="tooltip-text">' + this.tooltipText + '</div>';
    }
    tooltip.setAttribute('class', this.tooltipClass);
    tooltip.style.width = this.tooltipWidth + 'px';
    if (this.tooltipWidthMaxContent) {
      tooltip.style.width = 'max-content';
    }
    this.el.nativeElement.appendChild(tooltip);

    this.top ? tooltip.style.bottom = this.el.nativeElement.clientHeight + 'px' :
      tooltip.style.top = this.el.nativeElement.clientHeight + 'px';

    if (this.marginTop) {
      tooltip.style.top = this.el.nativeElement.clientHeight + this.marginTop + 'px';
    }

    this.left ? tooltip.style.left = 'calc( 50% - ' + (tooltip.offsetWidth / 2) + 'px)' :
      tooltip.style.left = (this.el.nativeElement.clientWidth + tooltip.offsetWidth) / 2 + 'px';

    if (this.center) {
      tooltip.style.left = '13px';
    }

    this.padding ? tooltip.style.padding = this.padding + 'px' : tooltip.style.padding = '20px';

    if (this.rightPosition) {
      tooltip.style.right = this.rightPosition + 'px';
      tooltip.style.left = 'auto';
    }

    this.tooltipReference = tooltip;
    setTimeout(() => {
      if (this.tooltipReference) {
        this.tooltipReference.remove();
        this.showingTooltip = false;
      }
    }, 60000); // Remove tooltip after 1 min if idle
  }
}
