import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LacUser } from '../../models/campaign.model';
import { UserPublicModalComponent } from '../user-public-modal/user-public-modal.component';
import { ModalConstants } from 'src/app/shared/services/modal/modal.constants';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { TeamLeaderboardModalComponent } from '../campaign/team-leaderboard-modal/team-leaderboard-modal.component';

@Component({
  selector: 'app-leaderboard-modal',
  templateUrl: './leaderboard-modal.component.html',
  styleUrls: ['./leaderboard-modal.component.scss']
})
export class LeaderboardModalComponent implements OnInit {

  endsWithS: boolean;
  screenWidth: number;
  screenHeight: number;
  teamsSelected = true;
  @Input() leaderboard: LacUser[];
  @Input() teamsLeaderboard: any[];
  @Input() company: string;
  @Input() canNavigateToUserProfile = true;
  @Input() teamsOnly: boolean;

  constructor(private dialogRef: MatDialogRef<LeaderboardModalComponent>,
              private modal: ModalService,
              @Inject(MAT_DIALOG_DATA) data) {
  }

  ngOnInit(): void {
    this.teamsSelected = this.teamsLeaderboard?.length >= 1;
    this.endsWithS = this.company?.slice(-1).toLowerCase() === 's';
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  closeModal() {
    this.dialogRef.close();
  }

  openUserPublicModal(accountId: number) {
    if (this.canNavigateToUserProfile) {
      const modalMinHeight = this.screenWidth > 767 ? (this.screenHeight <= 754 ? this.screenHeight : 754) : this.screenHeight;
      const modalMinWidth = this.screenWidth > 767 ? 816 : this.screenWidth;
      const userPublicModalRef = this.modal.openAdaptableModal({}, UserPublicModalComponent,
        this.screenWidth > 767 ? ModalConstants.DEFAULT_PANEL_CLASS : ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS,
        modalMinWidth, modalMinHeight, modalMinWidth, modalMinHeight);

      userPublicModalRef.componentInstance.accountId = accountId;
      userPublicModalRef.afterClosed().subscribe(() => {
        this.closeModal();
      });
    }
  }

  openTeamLeaderboardModal(team: any) {
    const modalMinHeight = this.screenWidth > 767 ? (this.screenHeight <= 754 ? this.screenHeight : 754) : this.screenHeight;
    const modalMinWidth = this.screenWidth > 767 ? 816 : this.screenWidth;
    const userPublicModalRef = this.modal.openAdaptableModal({}, TeamLeaderboardModalComponent,
      this.screenWidth > 767 ? ModalConstants.DEFAULT_PANEL_CLASS : ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS,
      modalMinWidth, modalMinHeight, modalMinWidth, modalMinHeight);

    userPublicModalRef.componentInstance.teamId = team.id;
    userPublicModalRef.componentInstance.teamName = team.name;
    userPublicModalRef.componentInstance.fullLeaderboard = this.leaderboard;
  }

  onImageError(user: LacUser): void {
    user.profile_picture = '/assets/icons/no-avatar.png';
  }
}
