import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { RouterUrls } from '../../shared/router-urls';
import { LanguageService } from '../../services/language.service';
import { CustomValidators } from '../../shared/custom-validators';
import { TagsService } from 'src/app/services/tags.service';

import {
  GoogleLoginProvider,
  MicrosoftLoginProvider,
  SocialAuthService,
  SocialUser
} from '@abacritt/angularx-social-login';
import { MicrosoftUser, SsoCredentials, User } from '../../shared/user.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthConstants } from '../auth.constants';
import { Constants } from '../../app.constants';
import { FrontendLogService } from '../../services/frontend-log.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit, AfterViewInit, OnDestroy {

  logInForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(CustomValidators.emailValidationRegex)]),
    password: new FormControl('', [Validators.required]),
    keepSignedIn: new FormControl(false, [])
  });
  isCapsOn: boolean;
  isPasswordShown = false;
  isSsoReady = false;
  userInteracted = false;
  userLoggingIn = false;
  errorMessage = '';
  // Error logging
  ssoProviderInfo: any;
  emailInfo: string;
  // END Error logging
  clickEventSubscription: Subscription;
  socialAuthSubscription: Subscription;
  RouterUrls = RouterUrls;
  AuthConstants = AuthConstants;
  currentLang = 'fr';
  graphMeEndpoint = 'https://graph.microsoft.com/v1.0/me';
  autoRedirectToSamlSSO = false;

  constructor(private formBuilder: UntypedFormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private titleService: Title,
              private meta: Meta,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private languageService: LanguageService,
              private socialAuthService: SocialAuthService,
              private frontendLogService: FrontendLogService,
              private tagsService: TagsService,
              private http: HttpClient) {
    this.clickEventSubscription = this.tagsService.getClickEventLogIn().subscribe(() => {
      this.updateTags();
    });
  }

  get email() {
    return this.logInForm.get('email');
  }

  get password() {
    return this.logInForm.get('password');
  }

  @HostListener('document:mousedown', ['$event'])
  detectUserInteraction() {
    this.userInteracted = true;
  }

  ngOnInit(): void {
    // Map url parameters to trigger actions like auto sso auth or assigning pledge id via URL parameter
    this.mapUrlParameters();
    this.setGoogleInteractionListener();
    this.updateTags();
    this.socialAuthService.initState.subscribe((isSsoReady) => {
      this.isSsoReady = isSsoReady;
    });
    this.socialAuthSubscription = this.socialAuthService.authState.subscribe({
      next: (user: SocialUser) => {
        if (this.userInteracted) {
          if (this.userLoggingIn) return;
          this.userLoggingIn = true;

          if (user.provider === MicrosoftLoginProvider.PROVIDER_ID) {
            const headers = new HttpHeaders({
              'Authorization': `Bearer ${ user.authToken }`
            });

            this.http.get(this.graphMeEndpoint, {headers: headers}).subscribe(
              (msUser: MicrosoftUser) => {
                this.handleSsoLogin(user, msUser);
              });
          } else {
            this.handleSsoLogin(user);
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        this.handleErrorResponse(error);
      }
    });
  }

  ngAfterViewInit() {
    const userServiceSub = this.userService.getUser().subscribe((user: User) => {
      if (user) {
        this.authService.handleAuthentication(user, true);
        userServiceSub.unsubscribe();
      }
    });

    this.languageService.language$.asObservable().subscribe({
      next: (lang: string) => {
        this.currentLang = lang;
      }
    });
  }

  mapUrlParameters() {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.autoRedirectToSamlSSO = queryParams.get('auto_saml') === 'true';
      if (this.autoRedirectToSamlSSO) {
        this.samlLogin();
      }
    });
  }

  samlLogin(): void {
    this.authService.goToSAMLAuth();
  }

  socialLogIn(type: string): void {
    if (this.userLoggingIn) return;
    this.userLoggingIn = true;

    const ssoProvider =
      type === AuthConstants.SSO_GOOGLE ? GoogleLoginProvider.PROVIDER_ID : MicrosoftLoginProvider.PROVIDER_ID;

    this.socialAuthService
      .signIn(ssoProvider)
      .then((user: SocialUser) => {
        if (user.provider === MicrosoftLoginProvider.PROVIDER_ID) {
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${ user.authToken }`
          });

          this.http.get(this.graphMeEndpoint, {headers: headers}).subscribe(
            (msUser: MicrosoftUser) => {
              this.handleSsoLogin(user, msUser);
            });
        } else {
          this.handleSsoLogin(user);
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.handleErrorResponse(error);
      });
  }

  handleSsoLogin(user: SocialUser, msUser?: MicrosoftUser): void {
    const ssoCredentials: SsoCredentials = {
      idtoken: user.idToken,
      email: user.email,
      type: user.provider.toLowerCase()
    };

    if (msUser) {
      ssoCredentials.surname = msUser.surname;
      ssoCredentials.lang = msUser.preferredLanguage ? msUser.preferredLanguage : this.currentLang;
    }

    this.ssoProviderInfo = ssoCredentials;

    this.authService
      .ssoLogin(ssoCredentials)
      .pipe(take(1))
      .subscribe({
        next: (loggedUser: User) => {
          this.authService.handleAuthentication(loggedUser);
        },
        error: (error: HttpErrorResponse) => {
          this.handleErrorResponse(error);
        }
      });
  }

  logIn() {
    this.emailInfo = this.logInForm.value.email;
    const userCredentials = {
      username: this.logInForm.value.email,
      password: this.logInForm.value.password
    };
    this.authService.logIn(userCredentials).subscribe(
      (user: User) => {
        this.authService.handleAuthentication(user, true);
      },
      (error: HttpErrorResponse) => {
        this.handleErrorResponse(error);
      }
    );
    this.logInForm.reset();
  }

  updateTags() {
    this.titleService.setTitle(this.translate.instant('LOG_IN_PAGE.meta_title_login'));
    this.meta.updateTag({ name: 'description', content: this.translate.instant('LOG_IN_PAGE.meta_description_login') });
    this.meta.updateTag({ property: 'og:title', content: this.translate.instant('LOG_IN_PAGE.meta_title_login') });
    this.meta.updateTag({
      property: 'og:description',
      content: this.translate.instant('LOG_IN_PAGE.meta_description_login')
    });
    this.meta.updateTag({ property: 'og:type', content: 'URL' });
    this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://noos.ams3.digitaloceanspaces.com/meta%20_%20landing.png'
    });
  }

  ngOnDestroy() {
    this.socialAuthSubscription.unsubscribe();
  }

  // Needed to detect if the user clicked the Google signIn button iframe.
  private setGoogleInteractionListener(): void {
    window.addEventListener('blur', () => {
      setTimeout(() => {
        if (document.activeElement.tagName === 'IFRAME') {
          this.userInteracted = true;
        }
      });
    }, { once: true });
  }

  private handleErrorResponse(error: HttpErrorResponse): void {
    let showError = true;
    this.userLoggingIn = false;

    if (error?.status === 404) {
      this.errorMessage = this.translate.instant('LOG_IN_PAGE.error_account_does_not_exist');
    } else if (error?.error?.non_field_errors) {
      this.errorMessage = this.translate.instant('LOG_IN_PAGE.error_wrong_credentials');
    } else if (error?.error === AuthConstants.GOOGLE_SSO_CLOSED_POPUP_ERROR) {
      showError = false;
    } else {
      this.errorMessage = AuthConstants.AUTH_ERROR_UNKNOWN;
    }
    this.email.setErrors({ wrongCredentials: showError });
    this.frontendLogService.postLogEntry({
      component: Constants.LOGIN_COMPONENT_NAME,
      file: Constants.LOGIN_COMPONENT_NAME,
      function: Constants.HANDLE_ERROR_RESPONSE_FUNCTION_NAME,
      content: {
        error,
        browserData: {
          cookies: window.navigator.cookieEnabled,
          os: window.navigator.platform,
          browser: window.navigator.userAgent
        },
        userContext: {
          email: this.emailInfo,
          ssoProviderInfo: this.ssoProviderInfo,
          frontendUserError: this.errorMessage
        }
      }
    }).subscribe({
      complete: () => {
        this.ssoProviderInfo = null;
      }
    });
  }
}
