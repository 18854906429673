<header [class.degraded]="isDegraded && !isScrolled && !isMobileMenuOpened && !transparentHeader"
        [class.transparent]="transparentHeader && !isScrolled && !isMobileMenuOpened"
        class="ns-header-logged">
  <!-- Desktop Header -->
  <div class="container">
    <div class="left-container">
      <a *ngIf="!isPledge" [routerLink]="currentLang">
        <img alt="NooS logo" class="noos-logo" src="/assets/icons/logo-white-2.svg">
      </a>
      <a *ngIf="isPledge" [routerLink]="[currentLang, RouterUrls.Home]">
        <img alt="Company logo" class="logo" src="{{ whiteLogo }}">
      </a>
      <a *ngIf="isPledge && !user.ex_employee" [routerLink]="[currentLang, RouterUrls.Home]" class="header-link ns-mr-18 ns-ml-36 align-self-start"
         routerLinkActive="active">{{'HEADER.home_header' | translate}}
      </a>
      <a *ngIf="isAdmin" class="admin-platform-button" href="https://admin.noos.global" target="_blank">
        <i class="fa-solid fa-arrow-up-right-from-square"></i>
        <span>{{'HEADER.admin_area_header' | translate }}</span>
      </a>
    </div>
    <div [class.onboarding-focus]="isWelcomeOnboardingFocusCenter" class="center-container">
      <a [routerLink]="[currentLang, RouterUrls.Campaign]" *ngIf="isPledge" class="header-link ns-mr-18 ns-ml-18"
         routerLinkActive="active">{{'HEADER.complete_challenges' | translate}}
        <div *ngIf="isCurrentCampaignUnseen" class="notification-dot"></div>
      </a>
      <a [routerLink]="[currentLang, RouterUrls.BrowseProjects]" class="header-link ns-mr-18 ns-ml-18"
         routerLinkActive="active">{{'HEADER.browse_header' | translate}}</a>
      <a *ngIf="isPledge && !user.ex_employee" [routerLink]="[currentLang, RouterUrls.VolunteeringOffers]"
         class="header-link ns-mr-18 ns-ml-18"
         routerLinkActive="active">{{'HEADER.volunteering_header' | translate}}</a>
      <a *ngIf="!isPledge || user.ex_employee" [routerLink]="[currentLang, RouterUrls.YourImpact]"
         class="header-link ns-mr-18 ns-ml-18"
         routerLinkActive="active">{{'HEADER.your_impact_header' | translate}}</a>
    </div>

    <div [class.onboarding-focus]="isWelcomeOnboardingFocusRight" class="right-container">
      <!-- See Impact Button -->
      <div (click)="openCloseImpactMenu()" *ngIf="isPledge && !user.ex_employee"
           class="header-link impact-button ns-mr-12 ns-ml-12"
      >{{'HEADER.see_impact_header' | translate}}
        <div [class.no-pointer-events]="!isImpactMenuOpened" class="dd-menu-wrapper-wrapper">
          <div class="dd-menu-wrapper">
            <ul *ngIf="isImpactMenuOpened" [@inOutAnimation] class="dd-menu">
              <li>
                <a [routerLink]="[currentLang, RouterUrls.YourImpact]" class="menu-list-element"
                   routerLinkActive="active">
                  <i class="fa-solid fa-bullseye ns-mr-8"></i>
                  {{'HEADER.your_impact_header' | translate}}</a>
              </li>
              <li *ngIf="isPledge && !user.ex_employee">
                <a [routerLink]="[currentLang, RouterUrls.Brands, companySlug]" class="menu-list-element"
                   routerLinkActive="active">
                  <i class="fa-regular fa-building ns-mr-8"></i>
                  {{ user.company_name }} </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Notifications Button -->
      <div (click)="showNotifications = !showNotifications" *ngIf="isPledge && hasLAC && !user.ex_employee" class="notifications">
        <i [class.active]="showNotifications" class="fa-regular fa-bell bell"></i>
        <span *ngIf="newNotifications >= 1" class="notification-counter">
          {{ newNotifications }}
        </span>
      </div>
      <!-- Profile Button -->
      <a (click)="openCloseUserMenu()"
         class="profile-picture"
         routerLinkActive="active">
        <img [src]="profilePicture" alt="Profile Picture">
        <span *ngIf="newNotifications >= 1 && !user.ex_employee" class="notification-counter">
          {{ newNotifications }}
        </span>
        <div [class.no-pointer-events]="!isUserMenuOpened && !isLanguageMenuOpened" class="dd-menu-wrapper-wrapper">
          <div class="dd-menu-wrapper">
            <ul *ngIf="isUserMenuOpened" [@inOutAnimation] class="dd-menu">
              <li>
                <a [routerLink]="[currentLang, RouterUrls.Profile]" class="menu-list-element"
                   routerLinkActive="active">
                  <i class="fa-regular fa-user ns-mr-8"></i>
                  {{'HEADER.your_profile_header' | translate}}
                </a>
              </li>
              <li *ngIf="isPledge && hasLAC && !user.ex_employee" class="tablet">
                <a (click)="showNotifications = true" class="menu-list-element notifications-2">
                  <i class="fa-regular fa-bell bell ns-mr-8"></i>
                  {{'HEADER.notifications_header' | translate}}
                  <span *ngIf="newNotifications >= 1" class="notification-dot"></span>
                </a>
              </li>
              <li>
                <a (click)="isLanguageMenuOpened = !isLanguageMenuOpened" class="menu-list-element globe-button">
                  <div></div>
                  {{'HEADER.languages' | translate}}
                  <i class="fa-solid fa-angle-right ns-ml-auto"></i>
                </a>
              </li>
              <li>
                <a (click)="logout()" [routerLink]="[currentLang, RouterUrls.LogIn]" class="menu-list-element">
                  <i class="fa-solid fa-arrow-right-from-bracket ns-mr-8"></i>
                  {{'HEADER.logout_header' | translate}}
                </a>
              </li>
            </ul>
            <!-- Language submenu -->
            <ul *ngIf="isLanguageMenuOpened" [@inOutAnimation] class="dd-menu language-menu">
              <li class="border-bottom">
                <a (click)="onGoBackOnLanguageMenu()" class="menu-list-element">
                  <i class="fa-solid fa-angle-left ns-mr-8"></i>
                  {{ 'HEADER.select_language' | translate }}
                </a>
              </li>
              <li>
                <a (click)="changeLanguage('es')" class="menu-list-element">
                  {{'LANGUAGES.spanish' | translate}}
                </a>
              </li>
              <li>
                <a (click)="changeLanguage('fr')" class="menu-list-element">
                  {{'LANGUAGES.french' | translate}}
                </a>
              </li>
              <li>
                <a (click)="changeLanguage('en')" class="menu-list-element">
                  {{'LANGUAGES.english' | translate}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </a>
    </div>
    <!-- Notifications Modal -->
    <app-notifications-card [notifications]="notifications" [showNotifications]="showNotifications"></app-notifications-card>
  </div>
  <!-- Mobile Header -->
  <div class="mobile-container">
    <div class="left-container">
      <img *ngIf="!isPledge" alt="NooS Logo" class="noos-logo" src="/assets/icons/logo-white-2-mobile.svg">
      <a *ngIf="isPledge" [routerLink]="[currentLang, RouterUrls.Home]">
        <img alt="Company logo" class="logo" src="{{ whiteLogo }}">
      </a>
    </div>
    <div class="right-container">
      <!-- Notifications Button -->
      <div (click)="openNotificationsModal()" *ngIf="isPledge && hasLAC && !user.ex_employee" class="notifications-mobile">
        <i [class.active]="showNotifications" class="fa-regular fa-bell bell"></i>
        <span *ngIf="newNotifications >= 1" class="notification-counter">
          {{ newNotifications }}
        </span>
      </div>
      <!-- Mobile Menu -->
      <div (click)="openCloseMobileMenu()" [class.close-icon]="isMobileMenuOpened"
           class="toggle d-flex align-items-center">
        <img [src]="isMobileMenuOpened ?
        '/assets/icons/cross-white.svg': '/assets/icons/unlogged-menu-toggler.svg'" alt="toggle">
      </div>
    </div>
  </div>
  <!-- Content Mask -->
  <div *ngIf="isContentMaskVisible" class="content-mask"></div>
  <!-- Onboardings -->
  <app-welcome-onboarding *ngIf="isPledge && user && !isWelcomeOnboardingCompleted && !user.no_home_top_section" [companyNameEn]="companyNameEn"
                          [companyName]="companyName"
                          [user]="user">
  </app-welcome-onboarding>
  <app-volunteering-onboarding *ngIf="isPledge && !isVolunteeringOnboardingCompleted && !user.no_home_top_section"></app-volunteering-onboarding>
  <app-support-onboarding *ngIf="isPledge && !isSupportOnboardingCompleted && !user.no_home_top_section"
                          [companyName]="companyName"></app-support-onboarding>
  <app-first-vote-onboarding *ngIf="isPledge && !isFirstVoteOnboardingCompleted && !user.no_home_top_section"></app-first-vote-onboarding>
</header>

<!-- Mobile Dropdown Menu -->
<div (click)="isMobileMenuOpened = false" *ngIf="isMobileMenuOpened" class="overlay"></div>
<div *ngIf="isMobileMenuOpened" class="mobile-menu">
  <div class="greeting">{{ 'HEADER.hi_header' | translate }} {{username}}
    , {{ 'HEADER.welcome_header' | translate }}</div>

  <ul class="menu">
    <li (click)="openCloseMobileMenu();" *ngIf="isPledge && !user.ex_employee"
        [routerLink]="[currentLang, RouterUrls.Home]"
        routerLinkActive="active-mobile">
      <a>{{ 'HEADER.home_header' | translate }}
      </a>
    </li>
    <li (click)="openCloseMobileMenu();" *ngIf="isPledge && !user.ex_employee"
        [routerLink]="[currentLang, RouterUrls.Campaign]"
        routerLinkActive="active-mobile">
      <a>{{ 'HEADER.complete_challenges' | translate }}
        <div *ngIf="isCurrentCampaignUnseen" class="notification-dot" style="top:2px;right:-8px;"></div>
      </a>
    </li>
    <li (click)="openCloseMobileMenu();" [routerLink]="[currentLang, RouterUrls.BrowseProjects]"
        routerLinkActive="active-mobile">
      <a>{{ 'HEADER.browse_header' | translate }}</a>
    </li>
    <li (click)="openCloseMobileMenu();" *ngIf="isPledge && !user.ex_employee"
        [routerLink]="[currentLang, RouterUrls.VolunteeringOffers]"
        routerLinkActive="active-mobile">
      <a>{{ 'HEADER.volunteering_header' | translate }}</a>
    </li>
    <li (click)="openCloseMobileMenu()" [routerLink]="[currentLang, RouterUrls.YourImpact]"
        routerLinkActive="active-mobile">
      <a>{{ 'HEADER.your_impact_header' | translate }}</a>
    </li>
    <li (click)="openCloseMobileMenu()" *ngIf="isPledge && !user.ex_employee"
        [routerLink]="[currentLang, RouterUrls.Brands, companySlug]"
        routerLinkActive="active-mobile">
      <a>{{ user.company_name }}</a>
    </li>
    <li (click)="openCloseMobileMenu();" [routerLink]="[currentLang, RouterUrls.Profile]"
        routerLinkActive="active-mobile">
      <a>{{'HEADER.your_profile_header' | translate}}</a>
    </li>
    <li (click)="openCloseMobileMenu();logout();" routerLinkActive="active-mobile">
      <a>{{ 'HEADER.logout_header' | translate }}</a>
    </li>
  </ul>
  <ul class="change-lang">
    <li routerLinkActive="active-lang">
      <a (click)="changeLanguage('es')"
         [ngClass]="{'active-lang': currentLang === '/es'}">ES
      </a>
    </li>
    <li routerLinkActive="active-lang">
      <a (click)="changeLanguage('fr')"
         [ngClass]="{'active-lang': currentLang === '/fr'}">FR
      </a>
    </li>
    <li routerLinkActive="active-lang">
      <a (click)="changeLanguage('en')"
         [ngClass]="{'active-lang': currentLang === '/en'}">EN
      </a>
    </li>
  </ul>
</div>
