import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as Projects from '../shared/projects.model';
import { Proposal } from '../shared/projects.model';
import * as User from '../shared/user.model';
import { AccountAuthType } from '../shared/user.model';
import { ApiUrls } from '../shared/api-urls';
import { FullImpactResponse } from '../shared/impact.model';
import {
  BrandImpactResponse,
  BrandProgramResponse,
  MatchingGiftResponse,
  ProjectObject,
  ValidateImpactRequest,
} from '../shared/brands.model';
import { environment } from 'src/environments/environment';
import { HttpErrorReport } from '../shared/models/http-error-report/http-error-report.model';
import {
  Payment,
  PaymentIntentResponse,
  PaymentStatusResponse,
  RequestPaymentStatus
} from '../shared/models/stripe/payment.model';
import { ExternalClick, VolunteeringApplicant } from '../browse-projects-module/models/volunteering-application.model';
import {
  PushEditUserPlayers,
  PushPreferences,
  PushPreferencesChange,
  PushPreferencesChangeResponse,
  PushPreferencesRequest
} from '../shared/models/push-notifications/push-notifications.model';
import { LogEntry } from '../shared/models/frontend-log-entry/log-entry.model';
import { EnvironmentResponse } from '../shared/models/systems/environment.model';
import { VolunteeringRelatedEmployees } from '../volunteering/models/volunteering-share.model';
import { LacChallengeData, LacCompleteChallengeRequest } from '../campaign-module/models/lac-challenge.model';
import {
  LacCompleteQuizResponse,
  LacQuizData,
  LacReadingContentData
} from '../campaign-module/models/learning-content.model';
import { EndCampaignInfo, LacChallengesResponse, LacLearningContentResponse } from '../campaign-module/campaign.models';
import { LacPublicProfile } from '../campaign-module/models/common.model';
import { HomePageInfo } from '../home-module/home.models';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private bypassInterceptorsHttp: HttpClient;

  constructor(private http: HttpClient,
              private handler: HttpBackend) {
    this.bypassInterceptorsHttp = new HttpClient(handler);
  }

  // User
  getCurrentUser(): Observable<User.User> {
    const url = ApiUrls.GetCurrentUser;
    return this.http.get<User.User>(url);
  }

  registerNewUser(userCredentials: object): Observable<User.User> {
    const url = ApiUrls.PostRegisterUser;
    return this.http.post<User.User>(url, userCredentials);
  }

  logInUser(userCredentials: object): Observable<User.User> {
    const url = ApiUrls.PostLogInUser;
    return this.http.post<User.User>(url, userCredentials);
  }

  logOutUser(): Observable<User.User> {
    const url = ApiUrls.PostLogOutUser;
    return this.http.post<User.User>(url, {});
  }

  ssoVerification(userCredentials: User.SsoCredentials): Observable<User.User> {
    const url = ApiUrls.PostSsoVerification;
    return this.http.post<User.User>(url, userCredentials);
  }

  ssoSignupVerification(userCredentials: User.SsoCredentials): Observable<User.User> {
    const url = ApiUrls.PostSsoSignupVerification;
    return this.http.post<User.User>(url, userCredentials);
  }

  getAccountAuthType(userEmail): Observable<AccountAuthType> {
    const url = ApiUrls.PostAccountAuthCheck;
    return this.http.post<AccountAuthType>(url, userEmail);
  }

  getClockInfo(): Observable<User.ClockInfo> {
    const url = ApiUrls.GetClockInfo;
    return this.http.get<User.ClockInfo>(url);
  }

  resetUserPassword(email: string) {
    const url = ApiUrls.PostResetPassword;
    const body = { email };
    return this.http.post<any>(url, body);
  }

  resetUserPasswordConfirm(token: string, password: string) {
    const url = ApiUrls.PostResetPasswordConfirm;
    const body = {
      token,
      password
    };
    return this.http.post<any>(url, body);
  }

  changeUserEmail(password: string, email: string, language: string) {
    const url = ApiUrls.PutChangeEmail;
    const body = {
      password,
      new_email: email,
      language
    };
    return this.http.post<any>(url, body);
  }

  changeUserPassword(oldPassword: string, newPassword: string) {
    const url = ApiUrls.PutChangePassword;
    const body = {
      old_password: oldPassword,
      new_password: newPassword
    };
    return this.http.post<any>(url, body);
  }

  getProfileSettings(): Observable<User.ProfileSettings> {
    const url = ApiUrls.GetProfileSettings;
    return this.http.get<User.ProfileSettings>(url);
  }

  getDonations(): Observable<User.DonationListResponse> {
    const url = ApiUrls.GetDonations;
    return this.http.get<User.DonationListResponse>(url);
  }

  getFullImpact(): Observable<FullImpactResponse> {
    const url = ApiUrls.GetFullImpact;
    return this.http.get<FullImpactResponse>(url);
  }

  getProfilePicture(): Observable<string> {
    const url = ApiUrls.GetProfilePicture;
    return this.http.get<string>(url);
  }

  uploadProfilePicture(picture: File): Observable<string> {
    const url = ApiUrls.UploadProfilePicture;
    const body = new FormData();
    body.append('file', picture, picture.name.replace(' ', ''));
    return this.http.post<string>(url, body);
  }

  getUserSubscription(): Observable<User.UserSubscription> {
    const url = ApiUrls.GetUserSubscription;
    return this.http.get<User.UserSubscription>(url);
  }

  getUserLastFourDigits(): Observable<User.PaymentInfo> {
    const url = ApiUrls.GetUserLastFourDigits;
    return this.http.get<User.PaymentInfo>(url);
  }

  saveCauses(causes: string[]) {
    const url = ApiUrls.PutSaveCauses;
    const body = {
      cause_preference_1: causes[0],
      cause_preference_2: causes[1]
    };
    return this.http.post<any>(url, body);
  }

  saveContinents(continents: string[]) {
    const url = ApiUrls.PutSaveContinents;
    const body = {
      continent_preference_1: continents[0],
      continent_preference_2: continents[1]
    };
    return this.http.post<any>(url, body);
  }

  saveCountry(country: string) {
    const url = ApiUrls.PutSaveCountry;
    const body = {
      tax_country: country
    };
    return this.http.post<any>(url, body);
  }

  editPaymentMethod(
    cardNumber: string,
    expMonth: string,
    expYear: string,
    cvc: string): Observable<any> {
    const url = ApiUrls.PutPaymentMethod;
    const body = {
      card_number: cardNumber,
      expiry_month: expMonth,
      expiry_year: expYear,
      cvc
    };
    return this.http.post<any>(url, body);
  }

  subscribeForMailing(email: string, language: string) {
    const url = ApiUrls.PostSubscribeNewsletter;
    const body = {
      email,
      language
    };
    return this.http.post<any>(url, body);
  }

  getHomePageInfo(user: User.User): Observable<HomePageInfo> {
    const url = ApiUrls.GetHomePageInfo;
    const body = {user};
    return this.http.post<any>(url, body);
  }

  // Projects
  getAllProjects(page?: number): Observable<Projects.ProjectListResponse> {
    const cacheBust = new Date().getTime();
    const url = ApiUrls.GetAllProjects + '?' + cacheBust;
    const params = new HttpParams();
    if (page) {
      params.append('page', page.toString());
    }
    return this.http.get<Projects.ProjectListResponse>(url, {params});
  }

  getAlsoLikeProjects(projectId?: number): Observable<Projects.ProjectListResponse> {
    let url = ApiUrls.GetAlsoLikeProjects;
    if (projectId) {
      url += '/' + projectId;
    }
    return this.http.get<Projects.ProjectListResponse>(url);
  }

  getProjectInfo(projectId: number): Observable<Projects.PartialProject> {
    const url = ApiUrls.GetProjectById + projectId;
    return this.http.get<Projects.PartialProject>(url);
  }

  getProjectKPI(projectId: number): Observable<Projects.ProjectKPI> {
    const url = ApiUrls.GetProjectKPI + projectId;
    return this.http.get<Projects.ProjectKPI>(url);
  }

  getFullProjectInfo(projectId: number): Observable<Projects.FullProject> {
    const url = ApiUrls.GetFullProjectById + projectId;
    return this.http.get<Projects.FullProject>(url);
  }

  getNoKpiProject(projectId: number): Observable<Projects.NoKpiProject> {
    const url = ApiUrls.GetNoKpiProjectById + projectId;
    return this.http.get<Projects.NoKpiProject>(url);
  }

  getSupportedProjects(): Observable<Projects.ProjectListResponse> {
    const url = ApiUrls.GetVotedProjects;
    return this.http.get<Projects.ProjectListResponse>(url);
  }

  voteProject(projectId: number): Observable<any> {
    const url = ApiUrls.VoteProject;
    const body = { project_id: projectId };
    return this.http.post<any>(url, body);
  }

  unvoteProject(projectId: number): Observable<any> {
    const url = ApiUrls.UnvoteProject;
    const body = { project_id: projectId };
    return this.http.post<any>(url, body);
  }

  getProjectArticles(projectId: number): Observable<Projects.ProjectArticleListResponse> {
    const url = `${ ApiUrls.GetProjectArticles }${ projectId }`;
    return this.http.get<Projects.ProjectArticleListResponse>(url);
  }

  getAllProjectVolunteeringOffers(): Observable<Projects.ProjectVolunteeringListResponse> {
    const url = `${ ApiUrls.GetAllVolunteeringProjects }`;
    return this.http.get<Projects.ProjectVolunteeringListResponse>(url);
  }

  getProjectVolunteeringOffers(projectId: number): Observable<Projects.ProjectVolunteeringListResponse> {
    const url = `${ ApiUrls.GetListVolunteeringProjects }${ projectId }`;
    return this.http.get<Projects.ProjectVolunteeringListResponse>(url);
  }

  newApplicantVolunteeringProject(data: VolunteeringApplicant): Observable<any> {
    const url = ApiUrls.SetApplicantVolunteeringProject;
    const body = { data };
    return this.http.post<any>(url, body);
  }

  newExternalClick(data: ExternalClick): Observable<any> {
    const url = ApiUrls.PostExternalClick;
    const body = { data };
    return this.http.post<any>(url, body);
  }

  getAccountVolunteeringApplications(accountId: string): Observable<any> {
    const url = ApiUrls.PostCheckAccountVolunteeringApplications;
    const body = { account: accountId };
    return this.http.post<any>(url, body);
  }

  getUserRelatedEmployees(accountId: number): Observable<VolunteeringRelatedEmployees> {
    const url = ApiUrls.PostListRelatedEmployees;
    const body = { account_id: accountId };
    return this.http.post<VolunteeringRelatedEmployees>(url, body);
  }

  postShareVolunteeringOffer(accountId: number, userMessage: string, offerId: number, recipientsIds: string): Observable<any> {
    const url = ApiUrls.PostShareVolunteeringOffer;
    const body = {
      account_id: accountId,
      user_message: userMessage,
      offer_id: offerId,
      recipients_ids: recipientsIds
    };
    return this.http.post<any>(url, body);
  }

  postProposal(proposal: Proposal): Observable<any> {
    const url = ApiUrls.PostProposal;
    const body = {
      account_id: proposal.account_id,
      type: proposal.type,
      organization_name: proposal.organization_name,
      organization_website: proposal.organization_website,
      contact_name: proposal.contact_name,
      contact_email: proposal.contact_email,
      contact_phone: proposal.contact_phone,
      explanation: proposal.explanation
    };
    return this.http.post<any>(url, body);
  }

  stopSubscription(language: string): Observable<any> {
    const url = ApiUrls.DeleteUserSubscription;
    const body = {
      language
    };
    return this.http.post<any>(url, body);
  }

  // Brands

  getBrands(): Observable<any> {
    const url = ApiUrls.Companies;
    const body = {
      api_token: environment.apiToken
    };
    return this.http.post<any>(url, body);
  }

  getBrandsAccessibility(): Observable<any> {
    const url = ApiUrls.AccessibleCompanies;
    const body = {
      api_token: environment.apiToken
    };
    return this.http.post<any>(url, body);
  }

  getBrand(brandSlug: string): Observable<BrandImpactResponse> {
    const url = ApiUrls.Company;
    const body = {
      api_token: environment.apiToken,
      company: brandSlug
    };
    return this.http.post<BrandImpactResponse>(url, body);
  }

  getMatchingGift(programSlug: string): Observable<MatchingGiftResponse> {
    const url = ApiUrls.MatchingGift + programSlug;
    return this.http.get<MatchingGiftResponse>(url);
  }

  getNoKpiMatchingGift(programSlug: string): Observable<MatchingGiftResponse> {
    const url = ApiUrls.NoKpi + programSlug;
    return this.http.get<MatchingGiftResponse>(url);
  }

  getCrisisEvent(programSlug: string): Observable<any> {
    const url = ApiUrls.CrisisEvent + programSlug;
    return this.http.get<any>(url);
  }

  checkPaymentIntent(payload: any): Observable<any> {
    const url = ApiUrls.CheckPayment;
    return this.http.post<any>(url, payload);
  }

  getProgramInfo(programSlug: string): Observable<BrandProgramResponse> {
    const url = ApiUrls.Program + programSlug;
    return this.http.get<BrandProgramResponse>(url);
  }

  getProgramImpact(programId: number): Observable<any> {
    const url = ApiUrls.ProgramImpact + programId;
    return this.http.get<ProjectObject[]>(url);
  }

  getMatchingGiftImpact(programId: number): Observable<any> {
    const url = ApiUrls.MatchingGiftImpact + programId;
    return this.http.get<ProjectObject[]>(url);
  }

  validateImpact(payload: ValidateImpactRequest): Observable<any> {
    const url = ApiUrls.ProgramVote;
    return this.http.post<any>(url, payload);
  }

  getProjectsOwnMoney(): Observable<Projects.ProjectListResponse> {
    const url = ApiUrls.GetSupportedOwnMoney;
    return this.http.get<Projects.ProjectListResponse>(url);
  }

  getProjectsCommunities(): Observable<Projects.ProjectListResponse> {
    const url = ApiUrls.GetSupportedCommunities;
    return this.http.get<Projects.ProjectListResponse>(url);
  }

  getBrandsImpact(): Observable<any> {
    const url = ApiUrls.BrandsImpact;
    const body = {
      api_token: environment.apiToken
    };
    return this.http.post<any>(url, body);
  }

  // HTTP Error Handling

  getHttpErrors(): Observable<any> {
    const url = ApiUrls.GetHttpErrors;
    return this.bypassInterceptorsHttp.get<any>(url);
  }

  reportHttpError(errorReport: HttpErrorReport): Observable<any> {
    const url = ApiUrls.PostHttpErrors;
    return this.bypassInterceptorsHttp
      .post<HttpErrorReport>(url, errorReport);
  }

  postFrontendLogEntry(logEntry: LogEntry): Observable<any> {
    const url = ApiUrls.PostFrontendLogEntry;
    return this.bypassInterceptorsHttp
      .post<LogEntry>(url, logEntry);
  }

  // Payments v2

  postLoggedPayment(paymentInfo: Payment): Observable<PaymentIntentResponse> {
    const url = ApiUrls.PostCreateLoggedPayment;
    return this.http.post<any>(url, paymentInfo);
  }

  postUnloggedPayment(paymentInfo: Payment): Observable<PaymentIntentResponse> {
    const url = ApiUrls.PostCreateUnloggedPayment;
    return this.http.post<any>(url, paymentInfo);
  }

  getPaymentStatus(paymentInfo: RequestPaymentStatus): Observable<PaymentStatusResponse> {
    const url = ApiUrls.PostGetPaymentStatus;
    return this.http.post<any>(url, paymentInfo);
  }

  // Push Notifications

  getCurrentPushPreferences(request: PushPreferencesRequest): Observable<PushPreferences> {
    request.api_token = environment.apiToken;
    const url = ApiUrls.PostGetCurrentPreferences;
    return this.http.post<PushPreferences>(url, request);
  }

  postChangePushPreferences(newPreferences: PushPreferencesChange): Observable<PushPreferencesChangeResponse> {
    newPreferences.api_token = environment.apiToken;
    const url = ApiUrls.PostChangePreferences;
    return this.http.post<PushPreferencesChangeResponse>(url, newPreferences);
  }

  postPlayerId(playerData: PushEditUserPlayers): Observable<any> {
    playerData.api_token = environment.apiToken;
    const url = ApiUrls.PostSetPlayerId;
    return this.http.post<any>(url, playerData);
  }

  // LAC

  getLacCampaign(accountId: number, accountEmail: string): Observable<any> {
    const url = ApiUrls.GetLacCampaign;
    const body = {
      account_id: accountId,
      account_email: accountEmail
    };
    return this.http.post<any>(url, body);
  }

  getLacFeed(accountId: number, accountEmail: string): Observable<any> {
    const url = ApiUrls.GetLacFeed;
    const body = {
      account_id: accountId,
      account_email: accountEmail
    };
    return this.http.post<any>(url, body);
  }

  getLacReadingContent(readingContentId: number, accountId: number): Observable<LacReadingContentData> {
    const url = ApiUrls.GetLacReadingContent;
    const body = {
      id: readingContentId,
      account_id: accountId
    };
    return this.http.post<LacReadingContentData>(url, body);
  }

  getLacQuiz(quizId: number, accountId: number): Observable<LacQuizData> {
    const url = ApiUrls.GetLacQuiz;
    const body = {
      id: quizId,
      account_id: accountId
    };
    return this.http.post<LacQuizData>(url, body);
  }

  postCompleteLacQuiz(quizId: number, accountId: number, correctAnswers: number): Observable<LacCompleteQuizResponse> {
    const url = ApiUrls.PostCompleteLacQuiz;
    const body = {
      id: quizId,
      account_id: accountId,
      correct_answers: correctAnswers
    };
    return this.http.post<LacCompleteQuizResponse>(url, body);
  }

  postCompleteLacReadingContent(articleId: number, accountId: number): Observable<any> {
    const url = ApiUrls.PostCompleteLacReadingContent;
    const body = {
      id: articleId,
      account_id: accountId,
    };
    return this.http.post<any>(url, body);
  }

  getLacChallenge(challengeId: string | number, accountId: number): Observable<LacChallengeData> {
    const url = `${ ApiUrls.GetChallenge }/${ challengeId }`;
    const body = {
      account_id: accountId,
    };
    return this.http.post<LacChallengeData>(url, body);
  }

  postCompleteChallenge(request: LacCompleteChallengeRequest): Observable<LacChallengeData> {
    const url = ApiUrls.PostCompleteChallenge;
    const body = {
      id: request.challenge_id,
      account_id: request.account_id,
      proof_answer: request.proof_answer,
      proof_question: request.proof_question,
      proof_comment: request.proof_comment,
      proof_media: request.proof_media,
    };
    return this.http.post<LacChallengeData>(url, body);
  }

  postLacChallengePictureUpload(file: File): Observable<any> {
    const url = ApiUrls.PostChallengePictureUpload;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(url, formData);
  }

  postShareChallenge(accountId: number, challengeId: number, ids: string, userMessage: string): Observable<any> {
    const url = ApiUrls.PostShareChallenge;
    const body = {
      id: challengeId,
      account_id: accountId,
      ids: ids,
      message: userMessage
    };
    return this.http.post<any>(url, body);
  }

  getLacChallenges(accountId: number): Observable<LacChallengesResponse> {
    const url = ApiUrls.PostChallenges;
    const body = {
      account_id: accountId
    };
    return this.http.post<LacChallengesResponse>(url, body);
  }

  getLacLearningContent(accountId: number): Observable<LacLearningContentResponse> {
    const url = ApiUrls.PostLearningContent;
    const body = {
      account_id: accountId
    };
    return this.http.post<LacLearningContentResponse>(url, body);
  }

  postLikeFeedContent(likeObject: any): Observable<any> {
    const url = ApiUrls.PostLikeFeedContent;
    return this.http.post<any>(url, likeObject);
  }

  postLikeFeedComment(likeObject: any): Observable<any> {
    const url = ApiUrls.PostLikeFeedComment;
    return this.http.post<any>(url, likeObject);
  }

  postLikeFeedReply(likeObject: any): Observable<any> {
    const url = ApiUrls.PostLikeFeedReply;
    return this.http.post<any>(url, likeObject);
  }

  postFeedComment(commentObject: any): Observable<any> {
    const url = ApiUrls.PostCreateFeedComment;
    return this.http.post<any>(url, commentObject);
  }

  postRemoveFeedComment(accountId: number, commentId: number): Observable<any> {
    const url = ApiUrls.PostRemoveFeedComment;
    const body = {
      account_id: accountId,
      comment_id: commentId
    };
    return this.http.post<any>(url, body);
  }

  postFeedReply(replyObject: any): Observable<any> {
    const url = ApiUrls.PostCreateFeedReply;
    return this.http.post<any>(url, replyObject);
  }

  postRemoveFeedReply(accountId: number, replyId: number): Observable<any> {
    const url = ApiUrls.PostRemoveFeedReply;
    const body = {
      account_id: accountId,
      reply_id: replyId
    };
    return this.http.post<any>(url, body);
  }

  getBadges(accountId: number): Observable<any> {
    const url = ApiUrls.PostBadges;
    const body = {
      account_id: accountId
    };
    return this.http.post<any>(url, body);
  }

  getUserPublic(accountId: number): Observable<LacPublicProfile> {
    const url = ApiUrls.PostUserPublic;
    const body = {
      account_id: accountId
    };
    return this.http.post<LacPublicProfile>(url, body);
  }

  getLastWeekChallenges(accountId: number): Observable<any> {
    const url = ApiUrls.PostLastWeekChallenges;
    const body = {
      account_id: accountId
    };
    return this.http.post<any>(url, body);
  }

  getNotifications(accountId: number): Observable<any> {
    const url = ApiUrls.GetNotifications;
    const body = {
      account_id: accountId
    };
    return this.http.post<any>(url, body);
  }

  getPost(notificationId: number): Observable<any> {
    const url = ApiUrls.GetPost;
    const body = {
      notification_id: notificationId
    };
    return this.http.post<any>(url, body);
  }

  getUsers(account_id: number, type: string, id: number): Observable<any> {
    const url = ApiUrls.PostUsers;
    const body = {
      account_id: account_id,
      type: type,
      id: id
    };
    return this.http.post<any>(url, body);
  }

  getTeams(account_id: number, type: string, id: number): Observable<any> {
    const url = ApiUrls.PostTeams;
    const body = {
      account_id: account_id,
      type: type,
      id: id
    };
    return this.http.post<any>(url, body);
  }

   getEndLacInfo(accountId: number): Observable<EndCampaignInfo> {
    const url = ApiUrls.PostEndLacInfo;
    const body = {
      account_id: accountId
    };
    return this.http.post<EndCampaignInfo>(url, body);
  }

  // System and server Endpoints

  getCurrentEnvironment(): Observable<EnvironmentResponse> {
    const url = ApiUrls.PostGetCurrentEnvironment;
    return this.http.get<EnvironmentResponse>(url);
  }

}

