<div class="card-container">
  <div class="card-item">
    <h2>{{ "LOG_IN_PAGE.login_login" | translate }}</h2>
    <div class="have-an-account">{{ "LOG_IN_PAGE.no_account_yet_login" | translate }}
      <a [routerLink]="['../', RouterUrls.SignUp]">{{ "LOG_IN_PAGE.sign_up_login" | translate }}</a>
    </div>
    <div class="d-flex align-items-center w-100 flex-column py-4">
      <!-- GOOGLE OAUTH SSO BUTTON  -->
      <asl-google-signin-button
          *ngIf="currentLang === 'fr'"
          locale="fr"
          size="large"
          type="standard"
          width="255px"
      ></asl-google-signin-button>
      <asl-google-signin-button
          (click)="userInteracted = true"
          *ngIf="currentLang === 'en'"
          locale="en"
          size="large"
          type="standard"
          width="255px"
      ></asl-google-signin-button>
      <asl-google-signin-button
          (click)="userInteracted = true"
          *ngIf="currentLang === 'es'"
          locale="es"
          size="large"
          type="standard"
          width="255px"
      ></asl-google-signin-button>
      <!-- Disabled status button, since asl-google-signin-button does not allow to style one -->
      <button *ngIf="!isSsoReady" class="btn sso-button disabled" disabled type="button">
        <img alt="Google Logo" src="assets/icons/google-auth.svg">
        <span>{{ "LOG_IN_PAGE.google_button" | translate }}</span>
      </button>
      <!-- MICROSOFT OAUTH SSO BUTTON  -->
      <button (click)="socialLogIn(AuthConstants.SSO_MICROSOFT)" [class.disabled]="!isSsoReady" [disabled]="!isSsoReady"
              class="btn sso-button ns-mt-8" type="button">
        <img alt="Microsoft Logo" id="microsoft-logo" src="assets/icons/microsoft-auth.svg">
        <span>{{ "LOG_IN_PAGE.microsoft_button" | translate }}</span>
      </button>
      <!-- SAML SSO BUTTON  -->
      <button (click)="samlLogin()" class="btn sso-button ns-mt-8" type="button">
        <span class="ns-ml-minus-5"> {{ 'LOG_IN_PAGE.login_with_sso' | translate }} </span>
      </button>
    </div>
    <div class="divider-with-text w-100 my-4"><span class="bg-white">{{ "LOG_IN_PAGE.or" | translate }}</span></div>

    <form [formGroup]="logInForm" (ngSubmit)="logIn()">
      <div class="form-grid">
        <label><span>{{ "LOG_IN_PAGE.email_login" | translate }}</span> <span> *</span>
          <input type="email" placeholder="example@mail.com" formControlName="email">
        </label>
        <label><span>{{ "LOG_IN_PAGE.password_login" | translate }}</span> <span> *</span>
          <input type="password" placeholder="*************" formControlName="password"
                 (appCapsLock)="isCapsOn = $event">
          <img loading="lazy" src="assets/icons/password-{{isPasswordShown ? 'shown' : 'hidden'}}.svg"
               alt="show password"
               (appShowPassword)="isPasswordShown = $event">
          <span class="caps-lock-alert" *ngIf="isCapsOn">{{ "LOG_IN_PAGE.caps_lock_login" | translate }}</span>
        </label>
      </div>

      <div *ngIf="logInForm.invalid" class="error-messages">
        <div *ngIf="email.errors?.required && email.touched">{{ "LOG_IN_PAGE.email_required_login" | translate }}</div>
        <div *ngIf="email.errors?.pattern && email.touched">{{ "LOG_IN_PAGE.email_structure_login" | translate }}</div>
        <div *ngIf="email.errors?.wrongCredentials">{{errorMessage}}</div>
        <div
          *ngIf="password.errors?.required && password.touched">{{ "LOG_IN_PAGE.password_required_login" | translate }}</div>
      </div>

      <a class="forgot-password"
         [routerLink]="['../', RouterUrls.ResetPassword]">{{ "LOG_IN_PAGE.forgot_password_login" | translate }}</a>

      <div class="checkboxes-container">
        <input type="checkbox" id="keepSignedIn" formControlName="keepSignedIn">
        <label for="keepSignedIn">{{ "LOG_IN_PAGE.keep_me_signed_in_login" | translate }}</label>
      </div>

      <button class="green sans-serif" type="submit"
              [disabled]="logInForm.invalid">{{ "LOG_IN_PAGE.login_button_login" | translate }}</button>
    </form>
  </div>
</div>
