import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LanguageService } from './services/language.service';
import { AuthService } from './services/auth.service';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { HttpErrorReport } from './shared/models/http-error-report/http-error-report.model';
import { environment } from '../environments/environment';
import { User } from './shared/user.model';
import { HttpErrorService } from './services/http-error.service';
import { Constants } from './app.constants';
import { ProjectsService } from './services/projects.service';
import { ModalService } from './shared/services/modal/modal.service';
import { SoftAskModalComponent } from './shared/modals/soft-ask-modal/soft-ask-modal.component';
import { OneSignal } from 'onesignal-ngx';
import { PushNotificationsService } from './shared/services/push-notifications.service';
import {
  PushPreferences,
  PushPreferencesChangeResponse,
  PushPreferencesModalResponse
} from './shared/models/push-notifications/push-notifications.model';
import { ApiService } from './services/api.service';
import { EnvironmentResponse } from './shared/models/systems/environment.model';
import { UserService } from './services/user.service';
import { ModalQueueService } from './shared/services/modal/modal-queue.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  user: User = null;
  screenWidth: number;
  isIOS: boolean;
  pushPreferences: PushPreferences;

  constructor(
    private router: Router,
    private oneSignal: OneSignal,
    private pushNotificationsService: PushNotificationsService,
    private languageService: LanguageService,
    private projectsService: ProjectsService,
    private modal: ModalService,
    private modalQueue: ModalQueueService,
    private authService: AuthService,
    private userService: UserService,
    private apiService: ApiService,
    private httpErrorService: HttpErrorService) {
    if (environment.staging || environment.production) {
      this.oneSignal.init({
        appId: environment.oneSignalToken,
      });
    }
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    this.languageService.setInitialLanguageSettings();
    this.authService.getCurrentUser().subscribe({
      next: (user: User) => {
        if (user) {
          this.userService.setUser(user);
          this.user = user;
          this.pushNotificationsService.getPreferences(user.id).subscribe((preferences: PushPreferences) => {
            this.pushPreferences = preferences;
            if (!this.pushPreferences.browser_notifications && preferences.browser_notifications_requested_times < 2
              && this.pushPreferences.has_votes && !this.isIOS) {
              // CASE: EXISTING USER NEVER ASKED
              if (this.pushPreferences.browser_notifications_requested_times === 0) {
                this.openSoftAskModal(Constants.PUSH_WAIT_SECONDS_TO_SHOW_SOFT_ASK);
              }
              // CASE: EXISTING USER ASKED ONCE
              else if (this.isTimeToAsk(this.pushPreferences.browser_notifications_last_date)) {
                this.openSoftAskModal(Constants.PUSH_WAIT_SECONDS_TO_SHOW_SOFT_ASK);
              }
            }
          });
        }
      }
    });
    // CASE: NEW USER
    const votedWithFiveVotesAvailableSub = this.projectsService.onVoteWithFiveVotesAvailable$.subscribe(() => {
      if (!this.isIOS) {
        if (!this.user) {
          this.user = this.authService.currentUserValue;
        }
        if (!this.pushPreferences) {
          const pushPreferencesSub = this.pushNotificationsService.getPreferences(this.user.id)
            .subscribe((preferences: PushPreferences) => {
              this.pushPreferences = preferences;
              if (!this.pushPreferences?.browser_notifications && this.user) {
                this.openSoftAskModal();
              }
              pushPreferencesSub.unsubscribe();
            });
        } else {
          if (!this.pushPreferences?.browser_notifications && this.user) {
            this.openSoftAskModal();
          }
        }
      }
      votedWithFiveVotesAvailableSub.unsubscribe();
    });
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const user = JSON.parse(localStorage.getItem('currentUser')) as User;

        if (event.urlAfterRedirects.includes('404')) {
          const errorReport: HttpErrorReport = {
            source: Constants.ERROR_SOURCE_NAME,
            referer_url: event.url,
            end_url: event.urlAfterRedirects,
            error_code: '404',
            account_id: 0,
            token: environment.backendLogToken
          };

          if (user?.id) {
            errorReport.account_id = user.id;
          }

          this.httpErrorService.reportHttpError(errorReport).subscribe();
        }
      });

    // Environment info for systems administration
    this.apiService.getCurrentEnvironment().subscribe((response: EnvironmentResponse) => {
      document.insertBefore(document.createComment(' ENV: ' + response?.environment + ' '), document.querySelector('html'));
    });
  }

  private isTimeToAsk(lastDateTimestamp: number): boolean {
    const currentDate: Date = new Date();
    const lastDatePlusWaitTime = new Date(lastDateTimestamp * 1000);
    lastDatePlusWaitTime.setDate(lastDatePlusWaitTime.getDate() + Constants.PUSH_WAIT_DAYS_TO_ASK_AGAIN);

    return lastDatePlusWaitTime < currentDate;
  }

  private openSoftAskModal(timeout = 0) {
    this.modalQueue.addModalToQueue({
      id: this.modalQueue.ids.SOFT_ASK_ID,
      priority: 100,
      layer: 9,
      openModalFn: () => {
        const modalHeight = this.screenWidth > 700 ? 215 : this.screenWidth > 500 ? 210 : 200;
        setTimeout(() => {
          const softAskModal = this.modal.openNotificationPopup({id: this.user.id}, SoftAskModalComponent,
            'no-padding-panel', 700, modalHeight, true);

          softAskModal.afterClosed().subscribe((modalResponse: PushPreferencesModalResponse) => {
            this.pushNotificationsService.changePreferences(this.user.id, modalResponse.status, modalResponse.native_status)
              .subscribe((response: PushPreferencesChangeResponse) => {
                if (modalResponse?.onesignal_player_id) {
                  this.pushNotificationsService.editUserPlayers(this.user.id, modalResponse.onesignal_player_id).subscribe();
                }
                this.pushPreferences = response.current;
                this.modalQueue.closeModal(this.modalQueue.ids.SOFT_ASK_ID)
              });
          });
        }, timeout);
      }
    });
  }
}
