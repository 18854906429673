import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { RouterUrls } from '../../router-urls';
import { LanguageService } from '../../../services/language.service';
import { AuthService } from '../../../services/auth.service';
import { LandingPageComponent } from '../../../core-page/landing-page/landing-page.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { ClockInfo, User } from '../../user.model';
import { Constants } from '../../../app.constants';
import { TranslateService } from '@ngx-translate/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { HeaderService } from '../../../services/header.service';
import { LacService } from 'src/app/services/lac.service';
import { ModalConstants } from '../../services/modal/modal.constants';
import { NotificationsModalComponent } from '../notifications-modal/notifications-modal.component';
import { ModalService } from '../../services/modal/modal.service';
import {
  EndCampaignModalComponent
} from '../../../campaign-module/components/end-campaign-modal/end-campaign-modal.component';
import { isMobile } from '../../../utils/screen.utils';
import { EndCampaignInfo } from '../../../campaign-module/campaign.models';
import { UserService } from '../../../services/user.service';
import { ModalQueueService } from '../../services/modal/modal-queue.service';

@Component({
  selector: 'app-header-logged',
  templateUrl: './header-logged.component.html',
  styleUrls: ['./header-logged.component.scss'],
  providers: [LandingPageComponent],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-10px)' }),
            animate('0.2s ease-out', style({ opacity: 1, transform: 'translateY(0px)' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, transform: 'translateY(0px)' }),
            animate('0.2s ease-in', style({ opacity: 0, transform: 'translateY(-10px)' }))
          ]
        )
      ]
    )
  ]
})
export class HeaderLoggedComponent implements OnInit, OnDestroy {
  Constants = Constants;
  RouterUrls = RouterUrls;

  @Input() transparentHeader: boolean;
  @Input() user: User;

  routerSubscription1: Subscription;

  username: string;
  companyName: string;
  companyNameEn: string;
  endsWithS: boolean;

  whiteLogo = '';
  profilePicture = '/assets/icons/no-avatar.png';
  companySlug = '';
  notifications = [];
  newNotifications = 0;

  showNotifications = false;
  hasLAC = true;
  isPledge = false;
  isAdmin = false;
  isMobileMenuOpened = false;
  isDegraded = true;
  isScrolled: boolean;
  isCurrentCampaignUnseen = true;
  isContentMaskVisible = false;

  // Onboardings
  isSupportOnboardingCompleted = true;
  isVolunteeringOnboardingCompleted = true;
  isFirstVoteOnboardingCompleted = true;
  isWelcomeOnboardingCompleted = true;
  isWelcomeOnboardingFocusCenter = false;
  isWelcomeOnboardingFocusRight = false;

  // Menus
  isImpactMenuOpened = false;
  isUserMenuOpened = false;
  isLanguageMenuOpened = false;

  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
              private languageService: LanguageService,
              private landingPage: LandingPageComponent,
              public translate: TranslateService,
              private projectsService: ProjectsService,
              private authService: AuthService,
              private userService: UserService,
              private lacService: LacService,
              private headerService: HeaderService,
              private modal: ModalService,
              private modalQueue: ModalQueueService) {
  }

  get currentLang() {
    return this.languageService.getCurrentLangWithSlash();
  }

  get currentLanguage(): any {
    return this.languageService.getUserUsedLanguage();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!event.target.classList.contains('notifications') &&
      !event.target.classList.contains('notifications-2')) {
      if (this.showNotifications) {
        this.showNotifications = false;
        if (this.user?.pledge_id !== null) {
          if (this.user?.has_lac) {
            this.lacService.getNotifications(this.user.id).subscribe(
              data => {
                this.notifications = data.notifications;
                this.newNotifications = data.new_notifications;
              }
            );
          }
        }
      }
    }
  }

  @HostListener('document:mousedown', ['$event'])
  handleOutsideClick(event) {
    if (this.isImpactMenuOpened
      && !event.target.classList.contains('dd-menu')
      && !event.target.classList.contains('menu-list-element')) {
      setTimeout(() => {
        this.isImpactMenuOpened = false;
      }, 200);
    }
    if (this.isUserMenuOpened
      && !event.target.classList.contains('impact-menu')
      && !event.target.classList.contains('menu-list-element')) {
      setTimeout(() => {
        this.isUserMenuOpened = false;
      }, 200);
    }
    if (this.isLanguageMenuOpened
      && !event.target.classList.contains('language-menu')
      && !event.target.classList.contains('menu-list-element')) {
      setTimeout(() => {
        this.isLanguageMenuOpened = false;
      }, 300);
    }
  }

  ngOnInit(): void {
    this.setupTriggers();
    this.username = this.user?.name;
    if (this.user?.pledge_id !== null) {
      this.isPledge = true;
      this.whiteLogo = this.user?.company_logo;
      this.companySlug = this.user?.company_slug;
      this.companyName = this.user.company_name;
      this.endsWithS = this.companyName?.slice(-1).toLowerCase() === 's';
      this.companyNameEn = !this.endsWithS ? this.companyName + '’s' : this.companyName + '’';
      this.isAdmin = this.user?.is_adminplatform_admin;
      // If LAC feature is active, we try to get the LAC notifications
      if (this.user?.has_lac) {
        this.hasLAC = true;
        this.lacService.getNotifications(this.user.id).subscribe(
          data => {
            this.notifications = data.notifications;
            this.newNotifications = data.new_notifications;
          }
        );
      }
      // If the LAC feature is active and the current campaign ended
      // and the user haven't seen the end campaign info
      if (this.user.end_lac) {
        this.lacService.getEndLacInfo(this.user.id).subscribe(
          {
            next: (info: EndCampaignInfo) => {
              if (info) {
                this.openEndCampaignModal(info);
              }
            }
          });
      }
    }

    this.routerSubscription1 = this.setRouteEvents();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.isScrolled = window.pageYOffset > 0;
  }

  changeLanguage(lang) {
    this.languageService.changeLanguage(lang);
  }

  openCloseMobileMenu() {
    this.isMobileMenuOpened = !this.isMobileMenuOpened;
  }

  openCloseImpactMenu() {
    this.isImpactMenuOpened = !this.isImpactMenuOpened;
  }

  openCloseUserMenu() {
    if (!this.isLanguageMenuOpened) {
      this.isUserMenuOpened = !this.isUserMenuOpened;
    }
  }

  setRouteEvents() {
    const notDegradedHeaderRoutes = [
      RouterUrls.SignUp,
      RouterUrls.LogIn,
      RouterUrls.ChangePassword,
      RouterUrls.ResetPassword,
      RouterUrls.ResetPasswordConfirm,
      RouterUrls.TermsOfService,
      RouterUrls.PrivacyPolicy,
      RouterUrls.Contact,
      RouterUrls.YourImpact,
      RouterUrls.Profile,
      RouterUrls.Home,
      'redirect=1',
    ];
    const degradedHeaderRoutes = [
      RouterUrls.BrowseProjects,
      RouterUrls.Brands
    ];
    const urlArray = this.router.url.split('/').join(',').split('?').join(',').split(',');
    for (const i in urlArray) {
      if (urlArray[i].includes('redirect=1')) {
        urlArray.push('redirect=1');
      }
    }
    this.isDegraded = !notDegradedHeaderRoutes.some(path => urlArray.includes(path));
    if (degradedHeaderRoutes.some(path => urlArray.includes(path)) && urlArray.length > 3) {
      this.isDegraded = true;
    }
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlArrayEvent = event.url.split('/').join(',').split('?').join(',').split(',');
        for (const i in urlArrayEvent) {
          if (urlArrayEvent[i].includes('redirect=1')) {
            urlArrayEvent.push('redirect=1');
          }
        }
        this.isDegraded = !notDegradedHeaderRoutes.some(path => urlArrayEvent.includes(path));
        if (degradedHeaderRoutes.some(path => urlArrayEvent.includes(path)) && urlArrayEvent.length > 3) {
          this.isDegraded = true;
        }
      }
    });
  }

  setupTriggers(): void {
    this.subscriptions.push(
      this.userService.getProfilePicture().subscribe((pictureUrl: string) => {
        this.profilePicture = pictureUrl;
      })
    );

    this.subscriptions.push(
      this.projectsService.clockInfo.asObservable().subscribe({
        next: (clockInfo: ClockInfo) => {
          this.isCurrentCampaignUnseen = clockInfo?.new_campaign;
        }
      })
    );

    this.subscriptions.push(
      this.headerService.$showNewCampaignIndicator.subscribe({
        next: (showNewCampaignIndicator: boolean) => {
          this.isCurrentCampaignUnseen = showNewCampaignIndicator;
        }
      })
    );

    this.subscriptions.push(
      this.headerService.$isContentMaskVisible.subscribe({
        next: (isContentMaskVisible: boolean) => {
          this.isContentMaskVisible = isContentMaskVisible;
          if (!isContentMaskVisible) {
            this.headerService.$isWelcomeOnboardingFocusRight.next(false);
            this.headerService.$isWelcomeOnboardingFocusCenter.next(false);
          }
        }
      })
    );

    this.subscriptions.push(
      this.headerService.$isWelcomeOnboardingFocusCenter.subscribe({
        next: (isFocusCenter: boolean) => {
          this.isWelcomeOnboardingFocusCenter = isFocusCenter;
        }
      })
    );

    this.subscriptions.push(
      this.headerService.$isWelcomeOnboardingFocusRight.subscribe({
        next: (isFocusRight: boolean) => {
          this.isWelcomeOnboardingFocusRight = isFocusRight;
          this.headerService.$isWelcomeOnboardingFocusCenter.next(false);
        }
      })
    );

    this.subscriptions.push(
      this.headerService.$profilePictureUpdated.subscribe({
        next: (imageUrl: string) => {
          this.profilePicture = imageUrl;
        }
      })
    );

    this.subscriptions.push(
      this.headerService.$isWelcomeOnboardingDone.subscribe({
        next: (isDone: boolean) => {
          this.isWelcomeOnboardingCompleted = isDone;
        }
      })
    );

    this.subscriptions.push(
      this.headerService.$isVolunteeringOnboardingDone.subscribe({
        next: (isDone: boolean) => {
          if (!isDone) {
            this.modalQueue.addModalToQueue({
              id: this.modalQueue.ids.VOLUNTEERING_OB_ID,
              layer: 7,
              priority: 103,
              openModalFn: () => {
                this.isVolunteeringOnboardingCompleted = false;
              }
            });
          } else {
            this.isVolunteeringOnboardingCompleted = true;
          }
        }
      })
    );

    this.subscriptions.push(
      this.headerService.$isSupportOnboardingDone.subscribe({
        next: (isDone: boolean) => {
          if (!isDone) {
            this.modalQueue.addModalToQueue({
              id: this.modalQueue.ids.SUPPORT_OB_ID,
              layer: 7,
              priority: 104,
              openModalFn: () => {
                this.isSupportOnboardingCompleted = false;
              }
            });
          } else {
            this.isSupportOnboardingCompleted = true;
          }
        }
      })
    );

    this.subscriptions.push(
      this.headerService.$isFirstVoteOnboardingDone.subscribe({
        next: (isDone: boolean) => {
          if (!isDone) {
            this.modalQueue.addModalToQueue({
              id: this.modalQueue.ids.FIRST_VOTE_OB_ID,
              layer: 7,
              priority: 105,
              openModalFn: () => {
                this.isFirstVoteOnboardingCompleted = false;
              }
            });
          } else {
            this.isFirstVoteOnboardingCompleted = true;
          }
        }
      })
    );
  }

  openNotificationsModal(): void {
    const notificationsModalRef = this.modal.openAdaptableModal({}, NotificationsModalComponent,
      ModalConstants.FULLSCREEN_VISIBLE_HEADER_PANEL_CLASS, window.innerWidth);
    notificationsModalRef.componentInstance.notifications = this.notifications;
    notificationsModalRef.afterClosed().subscribe({
      next: () => {
        this.isMobileMenuOpened = false;
      }
    });
  }

  openEndCampaignModal(endCampaignInfo: EndCampaignInfo): void {
    const modalHeight = isMobile() ? window.innerHeight : Math.min(window.innerHeight, 754);
    const modalWidth = isMobile() ? window.innerWidth : 816;

    const endCampaignModal = this.modal.openAdaptableModal({}, EndCampaignModalComponent,
      isMobile() ? ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS : ModalConstants.DEFAULT_PANEL_CLASS, modalWidth, modalHeight, modalWidth, modalHeight);

    endCampaignModal.componentInstance.endCampaignInfo = endCampaignInfo;
    endCampaignModal.componentInstance.companyName = this.user.company_name;

  }

  onGoBackOnLanguageMenu() {
    this.isLanguageMenuOpened = false;
    setTimeout(
      () => {
        this.isUserMenuOpened = true;
      }, 200
    );
  }

  logout() {
    this.authService.logOutUser().subscribe({
      next: () => {
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.LogIn]);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.routerSubscription1.unsubscribe();
  }
}
