<div (click)="closeModal()" class="ns-close-button"></div>
<div *ngIf="!endsWithS" [ngClass]="{'teams': teamsLeaderboard?.length >= 1}"
     class="main-title">{{ "LAC.leaderboard_1" | translate }} {{ company }}{{ "LAC.leaderboard_2_with_s" | translate }}</div>
<div *ngIf="endsWithS" [ngClass]="{'teams': teamsLeaderboard?.length >= 1}"
     class="main-title">{{ "LAC.leaderboard_1" | translate }} {{ company }}{{ "LAC.leaderboard_2_without_s" | translate }}</div>
<div *ngIf="teamsLeaderboard?.length >= 1 && !teamsOnly" class="teams-switch">
  <div (click)="teamsSelected = true" [ngClass]="{'active': teamsSelected}"
       class="option">{{ "LAC.teams" | translate }}</div>
  <div (click)="teamsSelected = false" [ngClass]="{'active': !teamsSelected}"
       class="option">{{ "LAC.individuals" | translate }}</div>
</div>
<div class="main-table" *ngIf="!teamsSelected">
  <div class="leaderboard-item" (click)="openUserPublicModal(user.account_id)" *ngFor="let user of leaderboard">
    <div [ngClass]="{'first': user.position === 1, 'is-you': user.current_user}"
         class="leaderboard-number">{{ user.position }}</div>
    <div class="leaderboard-picture" [ngClass]="{'first': user.position === 1}">
      <img (error)="onImageError(user)" src="{{ user.profile_picture }}" alt="item-image">
    </div>
    <div [ngClass]="{'first': user.position === 1, 'is-you': user.current_user}"
         class="leaderboard-name">{{ user.name }} {{ user.surname }}</div>
    <div class="leaderboard-points" [ngClass]="{'first': user.position === 1, 'is-you': user.current_user}">{{ user.points }}
      pts
    </div>
  </div>
</div>
<div class="main-table" *ngIf="teamsSelected">
  <div class="leaderboard-item" (click)="!teamsOnly? openTeamLeaderboardModal(team): {}"
      *ngFor="let team of teamsLeaderboard"
      [padding]="5"
      [tooltipText]="'LAC.team_score_tooltip' | translate"
      [tooltipWidth]="280"
      [top]="false"
      [rightPosition]="-130"
      [marginTop]="10"
      nsTooltip>
    <div class="leaderboard-number teams" [ngClass]="{'first': team.position === 1, 'is-you': team.current_team}">{{ team.position }}</div>
    <div class="crown" *ngIf="team.position === 1">
      <img src="/assets/icons/crown.svg" alt="crown">
     </div>
    <div class="leaderboard-name" [ngClass]="{'first': team.position === 1, 'is-you': team.current_team}">{{ team.name }}</div>
    <div class="leaderboard-points" [ngClass]="{'first': team.position === 1, 'is-you': team.current_team}">{{ team.points }}
      pts
    </div>
  </div>
</div>
