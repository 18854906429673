<div class="card-container">
  <div class="card-item">
    <h2>{{ "SIGN_UP_PAGE.signup_signup" | translate }}</h2>
    <div class="have-an-account">{{ "SIGN_UP_PAGE.already_have_account_signup" | translate }}
      <a
          [routerLink]="['../', RouterUrls.LogIn]">{{ "SIGN_UP_PAGE.login_signup" | translate }}</a>
    </div>
    <div class="d-flex justify-content-center align-items-center flex-column w-100 py-4">
      <!-- GOOGLE OAUTH SSO BUTTON  -->
      <asl-google-signin-button
          *ngIf="currentLang === 'fr'"
          locale="fr"
          size="large"
          text="signup_with"
          type="standard"
          width="255px"
      ></asl-google-signin-button>
      <asl-google-signin-button
          *ngIf="currentLang === 'en'"
          locale="en"
          size="large"
          text="signup_with"
          type="standard"
          width="255px"
      ></asl-google-signin-button>
      <asl-google-signin-button
          *ngIf="currentLang === 'es'"
          locale="es"
          size="large"
          text="signup_with"
          type="standard"
          width="255px"
      ></asl-google-signin-button>
      <!-- Disabled status button, since asl-google-signin-button does not allow to style one -->
      <button *ngIf="!isSsoReady" class="btn sso-button disabled" disabled type="button">
        <img alt="Google Logo" src="assets/icons/google-auth.svg">
        <span>{{ "SIGN_UP_PAGE.google_button" | translate }}</span>
      </button>
      <!-- MICROSOFT OAUTH SSO BUTTON  -->
      <button (click)="socialSignUp(AuthConstants.SSO_MICROSOFT)" class="btn sso-button ns-mt-8" type="button">
        <img alt="Microsoft Logo" id="microsoft-logo" src="assets/icons/microsoft-auth.svg">
        <span>{{ "SIGN_UP_PAGE.microsoft_button" | translate }}</span>
      </button>
      <!-- SAML SSO BUTTON  -->
      <button (click)="samlSignup()" class="btn sso-button ns-mt-8" type="button">
        <span class="ns-ml-minus-5"> {{ 'SIGN_UP_PAGE.signup_with_sso' | translate }} </span>
      </button>
    </div>
    <div class="divider-with-text w-100 my-4">
      <span class="bg-white">{{ "SIGN_UP_PAGE.or" | translate }}</span>
    </div>

    <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
      <div class="form-grid">
        <label>
          <span>{{ "SIGN_UP_PAGE.name_signup" | translate }}</span>
          <span> *</span>
          <input type="text" placeholder="Jane" formControlName="name">
        </label> <label>
        <span>{{ "SIGN_UP_PAGE.surname_signup" | translate }}</span>
        <input type="text" placeholder="Doe" formControlName="surname">
      </label> <label>
        <span>{{ "SIGN_UP_PAGE.email_signup" | translate }}</span>
        <span> *</span>
          <input type="email" placeholder="example@mail.com" formControlName="email">
        </label>
        <label><span>{{ "SIGN_UP_PAGE.confirm_email_signup" | translate }}</span> <span> *</span>
          <input type="email" placeholder="example@mail.com" formControlName="confirmEmail" appNoCopyPaste>
        </label>
        <label><span>{{ "SIGN_UP_PAGE.password_signup" | translate }}</span> <span> *</span>
          <input type="password" placeholder="*************" formControlName="password"
                 (appCapsLock)="isCapsOn = $event">
          <img loading="lazy" src="assets/icons/password-{{isPasswordShown ? 'shown' : 'hidden'}}.svg"
               alt="show password"
               (appShowPassword)="isPasswordShown = $event">
          <span class="caps-lock-alert" *ngIf="isCapsOn">{{ "SIGN_UP_PAGE.caps_lock_signup" | translate }}</span>
        </label>
      </div>


      <div class="password-strength"
           [ngSwitch]="passwordStrength">{{ "SIGN_UP_PAGE.password_strength_signup" | translate }}
        <span style="color: #EB5757;" *ngSwitchCase="0">{{ "SIGN_UP_PAGE.password_weak_signup" | translate }}</span>
        <span style="color: #EB5757;" *ngSwitchCase="1">{{ "SIGN_UP_PAGE.password_weak_signup" | translate }}</span>
        <span style="color: #E3A903;" *ngSwitchCase="2">{{ "SIGN_UP_PAGE.password_medium_signup" | translate }}</span>
        <span style="color: #E3A903;" *ngSwitchCase="3">{{ "SIGN_UP_PAGE.password_medium_signup" | translate }}</span>
        <span style="color: #34D1B0;" *ngSwitchCase="4">{{ "SIGN_UP_PAGE.password_strong_signup" | translate }}</span>
      </div>
      <password-strength-meter class="d-none" [password]="signUpForm.value.password"
                               (strengthChange)="changePassStrength($event)"></password-strength-meter>
      <div class="password-recommendation">
        {{ "SIGN_UP_PAGE.password_recommendation_signup" | translate }}
      </div>

      <div *ngIf="signUpForm.invalid" class="error-messages">
        <div
          *ngIf="name.errors?.required && name.touched && name.invalid">{{ "SIGN_UP_PAGE.name_required_signup" | translate }}</div>
        <div
          *ngIf="email.errors?.required && email.touched">{{ "SIGN_UP_PAGE.email_required_signup" | translate }}</div>
        <div
          *ngIf="email.errors?.pattern && email.touched">{{ "SIGN_UP_PAGE.email_structure_signup" | translate }}</div>
        <div *ngIf="email.errors?.emailExist">{{errorMessage}}</div>
        <div
          *ngIf="confirmEmail.errors?.required && confirmEmail.touched">{{ "SIGN_UP_PAGE.email_repeat_signup" | translate }}</div>
        <div
          *ngIf="confirmEmail.errors?.mustMatch && confirmEmail.touched">{{ "SIGN_UP_PAGE.different_mail_signup" | translate }}</div>
        <div
          *ngIf="password.errors?.required && password.touched">{{ "SIGN_UP_PAGE.password_required_signup" | translate }}</div>
        <div
          *ngIf="password.hasError('requireLetterAndNumber') && password.touched">{{ "SIGN_UP_PAGE.password_length_signup" | translate }}</div>
      </div>

      <div class="checkboxes-container">
        <input type="checkbox" id="keepSignedIn" formControlName="keepSignedIn">
        <label for="keepSignedIn">{{ "SIGN_UP_PAGE.keep_me_signed_in_signup" | translate }}</label>
        <input type="checkbox" id="agreement" formControlName="agreement">
        <label for="agreement">
          {{ "SIGN_UP_PAGE.agree_with_signup" | translate }} <a
          [routerLink]="['../', RouterUrls.TermsOfService]">{{ "SIGN_UP_PAGE.terms_of_service_signup" | translate }}</a>
        </label>
      </div>

      <button class="green sans-serif" type="submit"
              [disabled]="signUpForm.invalid">{{ "SIGN_UP_PAGE.signup_button_signup" | translate }}</button>
    </form>
  </div>
</div>
