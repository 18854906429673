import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '../../../app.constants';
import { CampaignConstants } from '../../campaign.constants';
import { MatDialogRef } from '@angular/material/dialog';
import { LeaderboardModalComponent } from '../leaderboard-modal/leaderboard-modal.component';
import { ModalConstants } from '../../../shared/services/modal/modal.constants';
import { isMobile } from '../../../utils/screen.utils';
import { ModalService } from '../../../shared/services/modal/modal.service';
import { EndCampaignInfo } from '../../campaign.models';
import { LanguageService } from '../../../services/language.service';
import { RouterUrls } from '../../../shared/router-urls';
import { Router } from '@angular/router';
import { LacUser } from '../../models/campaign.model';

@Component({
  selector: 'app-end-campaign-modal',
  templateUrl: './end-campaign-modal.component.html',
  styleUrls: ['./end-campaign-modal.component.scss']
})
export class EndCampaignModalComponent implements OnInit {
  readonly Constants = Constants;
  readonly CampaignConstants = CampaignConstants;

  screenWidth: number;
  screenHeight: number;

  @Input() endCampaignInfo: EndCampaignInfo;
  @Input() companyName: string;

  podiumPositions: LacUser[] = [];
  hasReward = true;
  isLoading = false;
  isMobile = false;

  constructor(private dialogRef: MatDialogRef<EndCampaignModalComponent>,
              private modal: ModalService,
              private languageService: LanguageService,
              private router: Router) {
  }

  get currentLanguage() {
    return this.languageService.getUserUsedLanguage();
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
    this.hasReward = this.endCampaignInfo.reward;

    if (this.endCampaignInfo.teams_leaderboard.length === 0) {
      // Sort by points and assign a position number
      this.endCampaignInfo.leaderboard = this.endCampaignInfo.leaderboard.sort((a, b) => b.points - a.points);
      this.endCampaignInfo.leaderboard.forEach((user: LacUser, index: number) => {
          user.position = index + 1;
        }
      );

      // Get the first 4 positions, and add the current user to the 4th position if is not in these 4
      this.podiumPositions = this.endCampaignInfo.leaderboard.slice(0, 4);
      const currentUser = this.endCampaignInfo.leaderboard.find((user: LacUser) => user.current_user);
      if (currentUser && currentUser.position > 4) {
        this.podiumPositions[3] = currentUser;
      }
    } else {
      // Sort by points and assign a position number
      this.endCampaignInfo.teams_leaderboard = this.endCampaignInfo.teams_leaderboard.sort((a, b) => b.points - a.points);
      this.endCampaignInfo.teams_leaderboard.forEach((team: any, index: number) => {
          team.position = index + 1;
        }
      );

      // Get the first 4 positions, and add the current team to the 4th position if is not in these 4
      this.podiumPositions = this.endCampaignInfo.teams_leaderboard.slice(0, 4);
      const currentTeam = this.endCampaignInfo.leaderboard.find((team: any) => team.current_team);
      if (currentTeam && currentTeam.position > 4) {
        this.podiumPositions[3] = currentTeam;
      }
    }
  }

  openLeaderboardModal(): void {
    const modalHeight = isMobile() ? window.innerHeight : Math.min(window.innerHeight, 754);
    const modalWidth = isMobile() ? window.innerWidth : 640;

    const leaderboardModalRef = this.modal.openAdaptableModal({}, LeaderboardModalComponent,
      isMobile() ? ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS : ModalConstants.DEFAULT_PANEL_CLASS, modalWidth, modalHeight, modalWidth, modalHeight);

    leaderboardModalRef.componentInstance.canNavigateToUserProfile = false;
    leaderboardModalRef.componentInstance.leaderboard = this.endCampaignInfo.leaderboard;
    leaderboardModalRef.componentInstance.company = this.companyName;
    leaderboardModalRef.componentInstance.teamsOnly = this.endCampaignInfo.teams_only;
  }

  openProjectPage() {
    const route = [this.languageService.getCurrentLangWithSlash(), RouterUrls.BrowseProjects,
      this.endCampaignInfo.reward_project_link];
    const url = this.router.serializeUrl(this.router.createUrlTree(route));
    window.open(url, '_blank');
  }

  openCampaignPage() {
    this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.YourImpact]);
    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }

}
