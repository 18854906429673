<ng-template [ngIf]="(isBrandPage$ | async) === false && (isDemoPage$ | async) === false  && !isPublicPage">
  <app-header-logged *ngIf="isUserLogged" [transparentHeader]="isHomePage" [user]="user"></app-header-logged>
  <app-header-unlogged *ngIf="!isUserLogged" [transparentHeader]="isHomePage"></app-header-unlogged>
</ng-template>
<ns-simple-public-header *ngIf="isPublicPage"></ns-simple-public-header>

<div class="spacer"></div>

<router-outlet></router-outlet>

<app-footer *ngIf="(isBrandPage$ | async) === false && (isDemoPage$ | async) === false && !isPublicPage"></app-footer>
<ns-simple-public-footer *ngIf="isPublicPage"></ns-simple-public-footer>
