import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { LacChallengeData, LacCompleteChallengeRequest } from '../campaign-module/models/lac-challenge.model';
import { Observable } from 'rxjs';
import { LacCompleteQuizResponse, LacQuizData, LacReadingContentData } from '../campaign-module/models/learning-content.model';
import { EndCampaignInfo, LacChallengesResponse, LacLearningContentResponse } from '../campaign-module/campaign.models';
import { LacPublicProfile } from '../campaign-module/models/common.model';

@Injectable({
  providedIn: 'root'
})
export class LacService {

  constructor(private apiService: ApiService) {
  }

  getCampaign(accountId: number, accountEmail: string): Observable<any> {
    return this.apiService.getLacCampaign(accountId, accountEmail);
  }

  getFeed(accountId: number, accountEmail: string): Observable<any> {
    return this.apiService.getLacFeed(accountId, accountEmail);
  }

  getLacLearningContent(accountId: number): Observable<LacLearningContentResponse> {
    return this.apiService.getLacLearningContent(accountId);
  }

  getLacReadingContent(readingContentId: number, accountId: number): Observable<LacReadingContentData> {
    return this.apiService.getLacReadingContent(readingContentId, accountId);
  }

  getLacQuiz(quizId: number, accountId: number): Observable<LacQuizData> {
    return this.apiService.getLacQuiz(quizId, accountId);
  }

  completeLacQuiz(quizId: number, accountId: number, correctAnswers: number): Observable<LacCompleteQuizResponse> {
    return this.apiService.postCompleteLacQuiz(quizId, accountId, correctAnswers);
  }

  completeLacReadingContent(readingContentId: number, accountId: number): Observable<any> {
    return this.apiService.postCompleteLacReadingContent(readingContentId, accountId);
  }

  getChallenge(challengeId: string | number, accountId: number): Observable<LacChallengeData> {
    return this.apiService.getLacChallenge(challengeId, accountId);
  }

  getChallenges(accountId: number): Observable<LacChallengesResponse> {
    return this.apiService.getLacChallenges(accountId);
  }

  completeChallenge(request: LacCompleteChallengeRequest): Observable<any> {
    return this.apiService.postCompleteChallenge(request);
  }

  uploadChallengePicture(picture: File): Observable<any> {
    return this.apiService.postLacChallengePictureUpload( picture);
  }

  shareChallenge(accountId: number, challengeId: number, ids: string, userMessage: string): Observable<any> {
    return this.apiService.postShareChallenge(accountId, challengeId, ids, userMessage);
  }

  getBadges(accountId: number): Observable<any> {
    return this.apiService.getBadges(accountId);
  }

  getUserPublic(accountId: number): Observable<LacPublicProfile> {
    return this.apiService.getUserPublic(accountId);
  }

  likeFeedContent(likeObject: any): Observable<any> {
    return this.apiService.postLikeFeedContent(likeObject);
  }

  likeFeedComment(likeObject: any): Observable<any> {
    return this.apiService.postLikeFeedComment(likeObject);
  }

  likeFeedReply(likeObject: any): Observable<any> {
    return this.apiService.postLikeFeedReply(likeObject);
  }

  commentFeedContent(commentObject: any): Observable<any> {
    return this.apiService.postFeedComment(commentObject);
  }

  removeFeedComment(accountId, commentId: number): Observable<any> {
    return this.apiService.postRemoveFeedComment(accountId, commentId);
  }

  replyFeedComment(replyObject: any): Observable<any> {
    return this.apiService.postFeedReply(replyObject);
  }

  removeFeedReply(accountId: number, replyId: number): Observable<any> {
    return this.apiService.postRemoveFeedReply(accountId, replyId);
  }

  getNotifications(accountId: number): Observable<any> {
    return this.apiService.getNotifications(accountId)
  }

  getPost(notificationId: number): Observable<any> {
    return this.apiService.getPost(notificationId);
  }

  getUsers(account_id: number, type: string, id: number): Observable<any> {
    return this.apiService.getUsers(account_id, type, id);
  }

  getTeams(account_id: number, type: string, id: number): Observable<any> {
    return this.apiService.getTeams(account_id, type, id);
  }

  getEndLacInfo(accountId: number): Observable<EndCampaignInfo> {
    return this.apiService.getEndLacInfo(accountId);
  }
}
